<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
            <h3>ข้อมูล Turn Over : {{ username }}</h3>
          </b-form-group>
          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div class="d-flex align-items-start py-50">
                    <div
                      class="btn-disable"
                    >
                      ประเภท :
                    </div>
                    <b-form-select
                      v-model="search_type"
                      :options="search_type_option"
                      placeholder="เลือกธนาคาร"
                    />
                  </div>
                  <div class="d-flex align-items-start py-50 mr-1">
                    <button
                      class="btn btn-primary ml-lg-25"
                      @click="getdata()"
                    >
                      ค้นหา
                    </button>
                  </div>
                  <div class="d-flex align-items-start py-50">
                    <button
                      class="btn btn-danger ml-lg-25"
                      @click="unlockAllTurn()"
                    >
                      Reset Turn
                    </button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(promotion)="data">
          <span v-if="!data.item.promotion"> เทิร์นเติมเงินไม่รับโปร </span>
          <span v-else> {{ data.item.promotion }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status === 3"
            variant="light-success"
          >
            ปลดล๊อคเทิร์นแล้ว
          </b-badge>
          <b-badge
            v-else
            variant="light-warning"
          >
            ยังไม่ปลดล๊อคเทิร์น
          </b-badge>
        </template>
        <template #cell(promotion_type)="data">
          <b-badge
            v-if="data.item.promotion === 'คูปอง'"
            variant="light-primary"
          >
            คูปอง
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'Cashback'"
            variant="light-primary"
          >
            คืนเงิน
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'CommissionFriend'"
            variant="light-primary"
          >
            คืนยอดเสียเพื่อน
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'คืนยอดเสีย'"
            variant="light-primary"
          >
            คืนยอดเสีย
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'ฝากแรก 500 รับ 200 บาท'"
            variant="light-primary"
          >
            โปรฝากแรก 500
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'Commission'"
            variant="light-primary"
          >
            commission
          </b-badge>
          <b-badge
            v-else-if="data.item.promotion === 'รางวัลกงล้อ'"
            variant="light-primary"
          >
            รางวัลกงล้อ
          </b-badge>
          <b-badge
            v-else
            variant="light-secondary"
          >
            อื่นๆ
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <button
              v-if="data.item.status !== 3"
              class="btn btn-info mr-lg-50"
              @click="EditTurn(data.item)"
            >
              ปรับ Turn
            </button>
            <button
              v-if="data.item.status !== 3"
              class="btn btn-warning"
              @click="unlockTurn(data.item)"
            >
              Clear Turn
            </button>
          </div>
        </template>
        <template #cell(timeshow)="data">
          <div v-if="data.item.resource === 'kbankstate'">
            {{ data.item.transDate }}
          </div>
          <div v-else>
            {{ data.item.time }}
          </div>
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      ref="edit-turn"
      title="ปรับ Turn"
      ok-title="ปรับ Turn"
      cancel-title="ยกเลิก"
      @ok="updateTurn(edit_data)"
    >
      <div class="row">
        <div class="col-md-6">
          <b-form-group>
            <label>TURN ที่ต้องทำ</label>
            <b-form-input
              id="floating-label2"
              v-model="edit_data.turn"
              type="text"
              placeholder=""
            />
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group>
            <label>TURN ที่เล่นแล้ว</label>
            <b-form-input
              id="floating-label2"
              v-model="edit_data.turnover"
              type="text"
              placeholder=""
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import {
  // BRow, BCol,
  // BInputGroupPrepend,
  BModal, BCard, BTable,
  // BAvatar,
  BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  BFormInput,
  // BButton,
  BCardBody, VBToggle, BOverlay, BIconController,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    // BRow,
    // BCol,
    // BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BBadge,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      username: this.$route.params.id,
      search_type_option: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'คูปอง' },
        { value: 2, text: 'คืนเงิน' },
        { value: 3, text: 'คืนยอดเสียเพื่อน' },
        { value: 4, text: 'คืนยอดเสียเพื่อน' },
        { value: 5, text: 'ฝากแรก 500 รับ 200 บาท' },
        { value: 6, text: 'Commission' },
        { value: 7, text: 'รางวัลกงล้อ' },
      ],
      search_type: null,
      show: true,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'status', label: 'สถานะ' },
        { key: 'promotion_type', label: 'ประเภท' },
        { key: 'promotion', label: 'ชื่อโปรโมชั่น' },
        { key: 'turn', label: 'Turn ที่ต้องทำ', formatter: value => Number(value).toFixed(2) },
        { key: 'turnover', label: 'Turn ที่เล่นแล้ว', formatter: value => Number(value).toFixed(2) },
        { key: 'update_by', label: 'ปลดเทิร์นโดย' },
        {
          key: 'created_at',
          label: 'เวลารับโปร',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'จัดการ' },
      ],
      /* eslint-disable global-require */
      items: [],
      edit_data: {
        turn: 0,
        turnover: 0,
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    async getdata() {
      try {
        const params = {
          username: this.username,
          search_type: this.search_type,
        }
        const { data } = await this.$http.get('history/turnover', { params })
        this.items = data.turnoverall
        this.totalRows = this.items.length
      } catch (e) {
        console.log(e)
        this.show = false
      } finally {
        this.show = false
      }
    },
    async unlockAllTurn() {
      try {
        this.$swal({
          title: 'แจ้งเตือน ',
          text: 'ยืนยันที่จะปลดเทิร์นทั้งหมดหรือไม่ ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const obj = {
              username: this.username,
            }
            // console.log(Obj)
            this.$http
              .post('/promotion/unlockedturn', obj)
              .then(response => {
                this.show = false
                this.getdata()
                if (response.data === 'success') {
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'ปลดล็อคเทิร์นทั้งหมดสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                } else {
                  this.$swal({
                    icon: 'warning',
                    title: 'Error',
                    text: 'ปลดล็อคเทิร์นไม่สำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal({
              icon: 'info',
              title: 'ยกเลิก',
              text: 'ยกเลิกปลดเทิร์นทั้งหมด',
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-info',
              },
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.show = false
      } finally {
        this.show = false
      }
    },
    async unlockTurn(obj) {
      try {
        this.$swal({
          title: 'แจ้งเตือน ',
          text: `ยืนยันที่จะปลดเทิร์น ${obj.promotion} หรือไม่ ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const objUp = {
              // eslint-disable-next-line no-underscore-dangle
              _id: obj._id,
              status: 3,
              turn: obj.turn,
              turnover: obj.turnover,
            }
            this.$http
              .post('/promotion/updateturn', objUp)
              .then(response => {
                this.show = false
                this.getdata()
                if (response.data === 'success') {
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'ปลดล็อคเทิร์นสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                } else {
                  this.$swal({
                    icon: 'warning',
                    title: 'Error',
                    text: 'ปลดล็อคเทิร์นไม่สำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal({
              icon: 'info',
              title: 'ยกเลิก',
              text: `ยกเลิกปลดเทิร์น ${obj.promotion}`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-info',
              },
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.show = false
      } finally {
        this.show = false
      }
    },
    async updateTurn(obj) {
      try {
        this.$swal({
          title: 'แจ้งเตือน ',
          text: `ยืนยันที่จะเปลี่ยน TURN ที่ต้องทำ:${obj.turn}และTURN ที่เล่นแล้ว:${obj.turnover} หรือไม่ ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.show = true
            const objUp = {
              // eslint-disable-next-line no-underscore-dangle
              _id: obj._id,
              status: obj.status,
              turn: obj.turn,
              turnover: obj.turnover,
            }
            this.$http
              .post('/promotion/updateturn', objUp)
              .then(response => {
                this.show = false
                this.getdata()
                if (response.data === 'success') {
                  this.$swal({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'เปลี่ยนเทิร์นสำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                } else {
                  this.$swal({
                    icon: 'warning',
                    title: 'Error',
                    text: 'เปลี่ยนเทิร์นไม่สำเร็จ',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
                }
              })
              .catch(error => console.log(error))
          } else {
            this.show = false
            this.$swal({
              icon: 'info',
              title: 'ยกเลิก',
              text: `ยกเลิกเปลี่ยน TURN ที่ต้องทำ:${obj.turn}และTURN ที่เล่นแล้ว:${obj.turnover}`,
              confirmButtonText: 'ปิด',
              customClass: {
                confirmButton: 'btn btn-info',
              },
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.show = false
      } finally {
        this.show = false
      }
    },
    EditTurn(obj) {
      this.edit_data = obj
      this.$refs['edit-turn'].show()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
